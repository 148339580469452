/* Mixins */
.access-config-body {
  background-color: #fff;
  padding: 40px;
}
.access-config-body sup {
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
}
.access-config-body .slider-wrapper {
  position: relative;
}
.access-config-body .slider-wrapper .slider-master .ant-slider-rail {
  background-color: #52c41a;
}
.access-config-body .slider-wrapper .slider-master .ant-slider-track-1 {
  background-color: #faad14;
}
.access-config-body .slider-wrapper .customer-slider-track-2-wrapper {
  position: relative;
  margin: 14px 6px 10px;
  padding: 4px 0;
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px);
}
.access-config-body .slider-wrapper .customer-slider-track-2-wrapper .customer-slider-track-2 {
  position: absolute;
  height: 4px;
  border-radius: 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  background-color: #dc4f59;
}